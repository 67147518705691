<template>
  <br><br><br><br>
  <main class="md:w-1/2 text-center m-auto">
    <router-view/>
  </main>

</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('@/assets/Poppins/poppins_devangari.woff2') format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('@/assets/Poppins/poppins_latinext.woff2') format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('@/assets/Poppins/poppins_latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-white;
    @apply bg-black;
    @apply justify-center;
    @apply flex;
    @apply text-center;
  }
  a {
    @apply text-blue-400;
  }
  h1 {
    @apply font-['Poppins'];
  }
}

.glow {
  -webkit-box-shadow:0px 0px 216px 121px rgba(32,106,152,1);
  -moz-box-shadow: 0px 0px 216px 121px rgba(32,106,152,1);
  box-shadow: 0px 0px 216px 121px rgba(32,106,152,1);
}

.hover\:glow:hover {
  -webkit-box-shadow:0px 0px 105px 45px rgba(88,101,242,0.9);
  -moz-box-shadow: 0px 0px 105px 45px rgba(88,101,242,0.9);
  box-shadow: 0px 0px 105px 45px rgba(88,101,242,0.9);
}

</style>
