<template>
  <div>
    <h1 class="text-3xl text-fuchsia">Sending you to steam!</h1><br><br>
    <p class="text-white">are you on mobile? Sorry this wont work for you :(</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  mounted() {
    setTimeout(() => {
      location.href = location.search.replace('?url=', '')
    }, 1000);
  }
}
</script>
