<template>
  <div>

    <img alt="Welcome wave" src="@/assets/hand-wave.svg" class="invert w-full opacity-25">
    <p class="text-2xl -mt-20">
      You have just installed Steam Linker,
      A era of awesome linking begins for your server.
      Your members will be proud of you. <br><br>Remember this exact moment.<br>
      <span class="text-blurple">{{ new Date().toLocaleString() }}</span>
    </p>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
