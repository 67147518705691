import { createApp } from 'vue'
import App from './App.vue'
import Landing from "@/components/Landing.vue";
import Welcome from "@/components/Welcome.vue";
import Redirect from "@/components/Redirect.vue";
import { inject } from '@vercel/analytics';

import * as VueRouter from 'vue-router'

const routes = [
    { path: '/', component: Landing },
    { path: '/welcome', component: Welcome },
    { path: '/redirect', component: Redirect },
]


const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHistory(),
    routes, // short for `routes: routes`
})


const app = createApp(App);
app.use(router);
app.mount('#app');

inject();
